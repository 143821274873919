import { useParams} from "wouter";

import { Box, Button, Menu, MenuItem } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useEffect, useState } from "react";
import styled from "styled-components";
import { visuallyHidden } from '@mui/utils';
import { useMediaQuery, useTheme } from '@mui/material';

import useGetClientLabData from '../../hooks/clients/useGetClientLabData';
import TableSearch from '../../shared/TableSearch';
import { navigate } from "wouter/use-browser-location";
import { TClientLab } from "../../utils/types/clientLabTypes";

type Order = 'asc' | 'desc';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0F3354",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  backgroundColor: '#0F3354',
  '&.MuiTableSortLabel-root': {
    backgroundColor: '#0F3354',
    color: 'white',
  },
  '&.MuiTableSortLabel-root.MuiButtonBase-root': {
    backgroundColor: '#0F3354',
    color: 'white',
  },
  '&.MuiTableSortLabel-root.MuiButtonBase-root.Mui-active': {
    color: 'white',
  },
  '&.MuiTableSortLabel-active': {
    fontSize: 14,
    color: 'white'
  },
    '& .MuiTableSortLabel-icon': {
      color: 'white !important',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "rgba(0, 180, 204, 0.08)",
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ClientLabs() {
  const { clientLabData, totalEntries, fetchData } = useGetClientLabData();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('company');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [inProgressSearchTerm, setInProgressSearchTerm] = useState<string>("");
  const [selectedRow, setSelectedRow]= useState<TClientLab | null>(null);

  const params = useParams();
  const clientId = params.id ?? "";


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));

  
  useEffect(() => {
    const fetchOrder = order === "asc" ? true : false; 

    fetchData(clientId, pageNumber, searchTerm, rowsPerPage, orderBy, fetchOrder);
  },  [fetchData, clientId, pageNumber, searchTerm, rowsPerPage, order, orderBy]);


  const clientLabTableData = clientLabData ?? [];

  const open = Boolean(anchorEl);
  const handleRowMenuClick = (event: React.MouseEvent<HTMLButtonElement>, row: TClientLab) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(property);
  };

  const handleNavigateToCreateSSR = () => {
    if(selectedRow !== null) navigate(`/ssr/${selectedRow.clientId}`)
  }

  return(
    <Box component="main" sx={{ flexGrow: 1}}>
        <div style={{backgroundColor: "white"}}>
          <div style={{marginLeft: "20px", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <TableSearch
              setSearchTerm={setSearchTerm}
              setPageNumber={setPageNumber}
              inProgressSearchTerm={inProgressSearchTerm}
              setInProgressSearchTerm={setInProgressSearchTerm}
              page={"Client Labs"}
            ></TableSearch> 
            <div>
              <Button variant="contained" size="medium" style={{marginRight: "20px"}}>Add Lab</Button>
            </div>
            
          </div>
            <TableContainer sx={{ height: isSmallScreen ? "58vh": "60vh", overflowY: "visible"}}>
              <Table sx={{ minWidth: 650 }} size={isSmallScreen ? 'small' : 'medium'} stickyHeader>
                <TableHead >
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      <StyledTableSortLabel
                        active={orderBy === 'labName'}
                        direction={order}
                        onClick={createSortHandler('labName')}
                        sx={{minWidth: "200px"}}                   
                        >Lab Name
                        {orderBy === 'labName' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'labSystem'}
                        direction={order}
                        onClick={createSortHandler('labSystem')}                   
                        >System
                        {orderBy === 'labSystem' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'labSysSerial'}
                        direction={order}
                        onClick={createSortHandler('labSysSerial')}                   
                        >Serial
                        {orderBy === 'labSysSerial' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'dateInstall'}
                        direction={order}
                        onClick={createSortHandler('dateInstall')}                   
                        >Install Date
                        {orderBy === 'dateInstall' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'labDemo'}
                        direction={order}
                        onClick={createSortHandler('labDemo')}                   
                        >Demo
                        {orderBy === 'labDemo' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'labValidation'}
                        direction={order}
                        onClick={createSortHandler('labValidation')}                   
                        >Validation
                        {orderBy === 'labValidation' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'labCity'}
                        direction={order}
                        onClick={createSortHandler('labCity')}                   
                        >City
                        {orderBy === 'labCity' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableSortLabel
                        active={orderBy === 'labZipcode'}
                        direction={order}
                        onClick={createSortHandler('labZipcode')}                   
                        >Zip
                        {orderBy === 'labZipcode' ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}                     
                      </StyledTableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell style={{minWidth: "200px"}}>
                      Facility Notes
                    </StyledTableCell>
                    <StyledTableCell>
                      Actions
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientLabTableData.map((row) => (
                    <StyledTableRow
                      key={row.labName}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.labName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.labSystem}
                      </StyledTableCell>
                      <StyledTableCell >{row.labSystemSerial}</StyledTableCell >
                      <StyledTableCell >{row.labDateInstall}</StyledTableCell>
                      <StyledTableCell >{row.labDemo}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.labValidation}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.labCity}</StyledTableCell>
                      <StyledTableCell  style={{width: '5px', whiteSpace: 'nowrap'}}>{row.labZipCode}</StyledTableCell>
                      <StyledTableCell  style={{width: '25px'}}>{row.labNote}</StyledTableCell>
                      <StyledTableCell style={{width: '1px', whiteSpace: 'nowrap'}}>
                        <Button
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(e) => handleRowMenuClick(e, row)}
                        >
                          <MoreVertIcon/>
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <hr></hr>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalEntries}
              rowsPerPage={rowsPerPage}
              page={pageNumber-1}
              onPageChange={(handleChangePage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{ boxShadow: 0 }}
            >
              <MenuItem onClick={() => handleNavigateToCreateSSR()}>View</MenuItem>
              <MenuItem onClick={handleClose}>Edit</MenuItem>
              <MenuItem onClick={handleClose}>Delete</MenuItem>
            </Menu>
        </div>
        <Box>
    </Box>
    </Box>
  )
}
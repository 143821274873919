import { createGlobalStyle } from "styled-components";


const GlobalStyle = createGlobalStyle`
body {
  font-family: 'Inter', sans-serif;
  background-color: #B7B2B240 !important;
}

.page-layout {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.content {
  flex: 1;
  display: flex;

}

.main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.viewport {
  overlfow-y: auto
  height: 100vh;
  width: 100vw;
  // margin: 0 24px 0 24px;
}
`;


export default GlobalStyle;
import React, { useCallback} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'

import useOneClientData from '../../hooks/clients/useOneClientData';
import { HTTPMethods } from '../../utils/urlPaths';


interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  clientId: number;
  clientName: string;
  onDeleteSuccess: () => void;
}

const DeleteClientModal: React.FC<ModalFormProps> = ({
  open,
  onClose,
  clientId,
  clientName,
  onDeleteSuccess
}) => {

  const { oneClientData, isLoadingClientHookData } = useOneClientData();

  const sendDeleteRequest = useCallback(
    (clientId: number) => {
      oneClientData(clientId, HTTPMethods.DELETE)  
    },
    [oneClientData]
  )

  const handleDelete = (id: number) => {
    sendDeleteRequest(id)
    if(!isLoadingClientHookData){
      onDeleteSuccess()
      onClose();
    }
  }
  
  return(
    <Dialog
      open={open}
      maxWidth={"sm"}
      fullWidth={false}
    >
      <DialogTitle>
        Delete Client
      </DialogTitle>
      <DialogContent>
        Are you sure you want to permanently delete {clientName}?
        This action cannot be undone and the information will be lost forever.

        Please confirm your decision before proceeding.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => handleDelete(clientId)}>Delete</Button>
      </DialogActions>
      
    </Dialog>
  )
}

export default DeleteClientModal


export enum UrlPaths {
  Dashboard="/dashboard",
  Clients="/clients",
  ClientDashboard="/client-dashboard/:id",
  Proposals="/proposals",
  SalesOrders="/sales-orders",
  PurchaseOrders="/purchase-orders",
  Invoices="/invoices",
  Home="/",
  Inventory="/inventory",
  Ssr="/ssr/:id",
}

export enum HTTPMethods {
  GET = "GET",
  DELETE = "DELETE",
  POST = "POST",
  PUT = "PUT",
}
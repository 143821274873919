import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";

const PAGE_SIZE = 10;

export default function useGetInventoryData() {
  const [isLoadinginventory, setIsLoadinginventory] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [iData, setInventoryData] = useState<any | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const currentFetchId = useRef(0)

  const fetchInventoryData = useCallback(
    async (pageNumber: number, searchTerm: string | null, pageSize:number | null, orderBy: string|null, ascending: boolean) => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlInventory?PageSize=${!!pageSize ? pageSize : 10}&PageNumber=${pageNumber}&OrderBy=${orderBy}&Ascending=${ascending}`;

      if (searchTerm != null) {
        url += "&SearchTerm=" + searchTerm;
      }

      setIsLoadinginventory(true);
      setError(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          // setInventoryData(responsejson.inventory);
          setInventoryData(responsejson);
          setTotalEntries(responsejson.metaData.totalEntries);
          setTotalPages(Math.ceil(totalEntries / PAGE_SIZE));
          setIsLoadinginventory(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setError(error);
        setIsLoadinginventory(false);
      }
    },
    [totalEntries]
  );

  const inventoryData = useMemo(() => {
    if (iData == null) {
      console.log("iData == null", iData)
      return null;
    }

    return iData.inventories.map((i: any) => {
      return {
        inventoryId: i.inventory.inventoryId,
        authorId: i.inventory.authorId,
        catalogId: i.inventory.catalogId,
        vendorId: i.inventory.vendorId,
        whId: i.inventory.whId,
        attachmentId: i.inventory.attachmentId,
        mkrId: i.inventory.mkrId,
        stockno: i.inventory.stockno,
        partno: i.inventory.partno,
        mfgno: i.inventory.mfgno,
        vendorPartno: i.inventory.vendorPartno,
        version: i.inventory.version,
        name: i.inventory.name,
        leadTime: i.inventory.leadTime,
        turnFrequency: i.inventory.turnFrequency,
        description: i.inventory.description,
        customerDesc: i.inventory.customerDesc,
        note: i.inventory.note,
        image: i.inventory.image,
        cost: i.inventory.cost,
        price: i.inventory.price,
        laborCost: i.inventory.laborCost,
        markUp: i.inventory.markUp,
        shippingFee: i.inventory.shippingFee,
        quantity: i.inventory.quantity,
        um: i.inventory.um,
        poUm: i.inventory.poUm,
        poQtyExchange: i.inventory.poQtyExchange,
        minimum: i.inventory.minimum,
        reorder: i.inventory.reorder,
        minqty: i.inventory.minqty,
        iType: i.inventory.iType,
        type: i.inventory.type,
        inspection: i.inventory.inspection,
        shelfLife: i.inventory.shelfLife,
        certReq: i.inventory.certReq,
        reuse: i.inventory.reuse,
        status: i.inventory.status,
        enable: i.inventory.enable,
        dateModifiedCost: i.inventory.dateModifiedCost,
        poId: i.inventory.poId,
        asmType: i.inventory.asmType,
      }})
  }, [iData]);

  return { inventoryData, totalPages, totalEntries, isLoadinginventory, error, fetchInventoryData };
}

import { useCallback, useState, useRef } from "react";
import { HTTPMethods, UrlPaths } from "../../utils/urlPaths";
import { TClientData } from "../../utils/types/clientTypes";
import { useLocation } from "wouter";
import { headers } from '../authHeader'

export default function useOneClientData() {
  const [isLoadingClientHookData, setIsLoadingClientHookData] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [clientHookData, setClientHookData] = useState<TClientData | null>(null);
  const [, setLocation] = useLocation();
  const currentFetchId = useRef(0)

  const oneClientData = useCallback(
    async (id: number, method: HTTPMethods, clientHookData?: TClientData) => {
      const fetchId = ++currentFetchId.current
      const url = "https://pls-api-appservice.azurewebsites.net/api/TlClient/" + id;

      setIsLoadingClientHookData(true);
      setError(null);
      try {
        let response = null;
        if (method === HTTPMethods.GET || method === HTTPMethods.DELETE) {
          response = await fetch(url, {
            headers,
            method,
          });
        }

        if (method === HTTPMethods.PUT) {

          response = await fetch(url, {
            headers,
            method,
            body: JSON.stringify(clientHookData),
          });
          if(fetchId === currentFetchId.current){
            setIsLoadingClientHookData(false);
          }
        }
 
        if (response == null) {
          throw new Error("Response is null");
        }
        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        if (method === HTTPMethods.GET) {
          if(fetchId === currentFetchId.current){
            setClientHookData(await response.json());
            setIsLoadingClientHookData(false);
          }
        }
        if (method === HTTPMethods.DELETE) {
          if(fetchId === currentFetchId.current){
            setLocation(UrlPaths.Clients);
            setIsLoadingClientHookData(false);
          }
        }
      } catch (error: any) {
        setError(error);
        setIsLoadingClientHookData(false);
      }
    },
    [setLocation]
  );

  return { oneClientData, clientHookData, isLoadingClientHookData, error };
}

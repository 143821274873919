import {z} from 'zod';

const phoneSchema = z.array(z.object({
  type: z.string(),
  number: z.string()
}));
 
export const clientDataSchema = z.object({
  clientId: z.number().or(z.string()).optional(),
  userId: z.number().or(z.string()).optional(),
  mkrId: z.number().min(1, {message: 'Markup Rate is required'}).or(z.string().min(1, {message: 'Markup Rate is required'})),
  termId: z.number().min(1, {message: 'Net Term is required'}).or(z.string().min(1, {message: 'Net Term is required'})),
  taxId: z.number().min(1, {message: 'Tax type is required'}).or(z.string().min(1, {message: 'Tax type is required'})),
  marketId: z.number().min(1, {message: 'Market is required'}).or(z.string().min(1, {message: 'Market is required'})),
  accountno: z.string(),
  company: z.string().min(1, {message: 'Company is required'}),
  aka: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  title: z.string(),
  email: z.string().email().optional().or(z.literal('')),
  password: z.string().or(z.string()).optional(),
  mainPhone: z.string(),
  address: z.string(),
  city: z.string(),
  stateCode: z.string(),  
  zipcode: z.string(),
  primaryNote: z.string(),
  billAddress: z.string(),
  shipAddress: z.string(),
  shipNote: z.string(),
  secondFname: z.string(),
  secondLname: z.string(),
  secondEmail: z.string().email().optional().or(z.literal('')),
  secondPhone: phoneSchema,
  secondTitle: z.string(),
  secondNote: z.string(),
  accFname: z.string(),
  accLname: z.string(),
  accEmail: z.string().email().optional().or(z.literal('')),
  accPhone: phoneSchema,
  accTitle: z.string(),
  accNote: z.string(),
  finW9: z.string(),
  poFname: z.string(),
  poLname: z.string(),
  poEmail: z.string().email().optional().or(z.literal('')),
  poPhone: phoneSchema,
  poTitle: z.string(),
  poNote: z.string(),
  phoneList: phoneSchema,
  mobile: z.string(),
  home: z.string(),
  homePage: z.string(),
  terms: z.string(),
  linkedin: z.string(),
  note: z.string(),
  shippingMethod: z.number().min(1, {message: 'Shipping Method is required'}).or(z.string().min(1, {message: 'Shipping Method is required'})),
  balance: z.string().transform((val) => Number(val.replace(/[^\d.-]/g, ''))).or(z.number()),
  status: z.string().min(1, {message: 'Status is required'}).transform((val) => Number(val)).or(z.number()).or(z.number()),
  isTax: z.boolean(),
  enable: z.boolean().or(z.string()).optional(),
  isContract: z.boolean(),
  supAccountno: z.string()
})

export type FormInputData = z.input<typeof clientDataSchema>
export type FormOutputData = z.output<typeof clientDataSchema>

import { z } from 'zod';

export const ssrFormSchema = z.object({
  general: z.object({
    contact: z.string().min(2, 'Contact must be at least 2 characters'),
    phone: z.string(),
    comissionDate: z.string(),
    validatedPorts: z.string(),
    warrantyService: z.boolean(),
    scope: z.number().min(1, {message: 'Scope is required'}).or(z.string().min(1, {message: 'Scope is required'})),
    priorDate: z.string(),
    priorVolume: z.string(),
    currentVolume: z.string(),
    servicedVolume: z.string(),
    totalVolume: z.string(),
    totalMonths: z.string(),
    globalReset: z.boolean(),
  }),
  address: z.object({
    street: z.string().min(5, 'Street address must be at least 5 characters'),
    city: z.string().min(2, 'City must be at least 2 characters'),
    zipCode: z.string().regex(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format'),
  }),
  preferences: z.object({
    newsletter: z.boolean(),
    theme: z.enum(['light', 'dark', 'system']),
  }),
});

export type FormData = z.infer<typeof ssrFormSchema>;
import React, { useEffect } from 'react';
import { useFieldArray, UseFormRegister, Control, useWatch } from 'react-hook-form';
import { TextField, Button, FormControl, MenuItem } from '@mui/material';

interface PhoneNumber {
  type: string;
  number: string;
}

interface PhoneNumberFieldsProps {
  phoneLabel: string;
  fieldArrayName: string;
  control: Control<any>;
  register: UseFormRegister<any>;
  defaultValue?: PhoneNumber;
  initialData?: PhoneNumber[];
}

const PhoneNumberFields: React.FC<PhoneNumberFieldsProps> = ({
  phoneLabel,
  fieldArrayName,
  control,
  register,
  defaultValue = { type: '', number: '' },
  initialData
}) => {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: fieldArrayName
  });

  const fieldValueArray = useWatch({
    control,
    name: fieldArrayName
  });

  useEffect(() => {
    if (initialData && initialData.length > 0) {
      replace(initialData);
    }
  }, [initialData, replace]);

  const phoneTypes = [
    { value: 'main', label: 'General' },
    { value: 'work', label: 'Office' },
    { value: 'mobile', label: 'Mobile 1'},
    { value: 'mobile2', label: 'Mobile 2'},
    {value: 'fax', label: 'Fax'},
    { value: 'other', label: 'Other'}
  ];

  return (
    <div className="space-y-4">
      {fields.map((field, index) => {
        const currentValue = fieldValueArray?.[index];
        
        return (
          <div 
            key={field.id} 
            className="flex gap-4 items-center"
            style={{display: "flex", alignItems: "center"}}
          >
            <FormControl>
              <TextField
                select
                {...register(`${fieldArrayName}.${index}.type`)}
                label="Phone Type"
                value={currentValue?.type || ''}
                sx={{ maxWidth: "150px" }}
              >
                <MenuItem value="">Select Type</MenuItem>
                {phoneTypes.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <FormControl>
              <TextField
                {...register(`${fieldArrayName}.${index}.number`)}
                label={phoneLabel}
                placeholder="(123) 456-7890"
                value={currentValue?.number || ''}
                style={fields.length === 1 ? {width: "300px"} : {}}
              />
            </FormControl>

            {fields.length > 1 && (
              <Button
                variant="outlined"
                color="error"
                onClick={() => remove(index)}
                sx={{ marginLeft: "10px", height: "56px" }}
              >
                X
              </Button>
            )}
          </div>
        );
      })}

      <Button
        className="ml-2"
        variant="contained"
        onClick={() => append(defaultValue)}
        sx={{marginLeft: "8px", marginTop: "8px", marginBottom: "8px"}}
      >
        Add Phone Number
      </Button>
    </div>
  );
};

export default PhoneNumberFields;
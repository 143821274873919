import { useCallback, useMemo, useState, useRef} from "react";

import { headers } from "../authHeader";
import { TScope } from "../../utils/types/scopesTypes";

export default function useGetScopesData() {
  const [isLoadingScopes, setIsLoadingScopes] = useState<boolean>(false);
  const [errorScopes, setErrorScopes] = useState<any>(null);
  const [scopesState, setScopesState] = useState<Array<TScope> | null>(null);
  const currentFetchId = useRef(0)

  const fetchScopesData = useCallback(
    async () => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlScope`

      setIsLoadingScopes(true);
      setErrorScopes(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        })

        if(!response.ok){
          throw new Error(
            "Server returned error code: " +
            response.status + 
            " " +
            (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setScopesState(responsejson);
          setIsLoadingScopes(false)
        } else {
          console.log("")
        }
      } catch (error: any) {
        setErrorScopes(error);
        setIsLoadingScopes(false);
      }
    }, []
  );

  const scopesData = useMemo(() => {
    if (scopesState === null){
      return null
    }
    return scopesState.map(({scopeId, scopeName, description, quoteType, status}) =>
    ({
         scopeId,
         scopeName,
         description,
         quoteType,
         status
    })
  )
  }, [scopesState]);

  return { scopesData, isLoadingScopes, errorScopes, fetchScopesData }
}

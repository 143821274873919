import React from 'react';

import { 
  TextField, 
  Box, 
  FormControl, 
  MenuItem, 
  FormControlLabel, 
  Switch, 
  InputAdornment, 
  Typography, 
  Tooltip,
  CardContent,
} from '@mui/material';
import Card from '@mui/material/Card';
import { 
  UseFormRegister, 
  FieldErrors, 
  Controller, 
  Control, 
  UseFormWatch,
} from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FormData } from '../../../utils/schemas/ssrSchema';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { TScope } from '../../../utils/types/scopesTypes';

interface PersonalInfoTabProps {
  register: UseFormRegister<FormData>;
  watch?: UseFormWatch<FormData>;
  scopesData: TScope[] | null;
  isLoadingScopes: boolean;
  errors: FieldErrors<FormData>;
  control: Control<FormData>;
}

export const SsrGeneralForm: React.FC<PersonalInfoTabProps> = ({ 
  register,
  watch,
  scopesData,
  isLoadingScopes,
  errors,
  control
}) => {

  const selectedScope = !!watch ?  watch('general.scope'): null

  return (
    scopesData !== null && !isLoadingScopes ? (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
      <Box style={{width: "100%", fontSize: "20px", marginTop: "10px", color: '#0F3354'}}>
        General
      </Box>
      
      <TextField
        sx={{width: 400}}
        label="Contact"
        type="contact"
        {...register('general.contact')}
        error={!!errors.general?.contact}
        helperText={errors.general?.contact?.message}
        fullWidth
      />

      <Controller
        name="general.phone"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <PatternFormat 
            format="( ### )  ### - ####"
            allowEmptyFormatting 
            mask="_"
            label="Phone"
            sx={{width: 400}} 
            customInput={TextField}
            getInputRef={ref}
            error={!!errors.general?.phone}
            helperText={errors.general?.phone?.message}
            {...rest}
          />
        )}
      />

      <Controller
        name="general.comissionDate"
        control={control}
        render={({ field }) => (
          <DatePicker
            label="Commission Date"
            value={field.value}
            onChange={(newValue) => field.onChange(newValue)}
            slotProps={{
              textField: {
                error: !!errors.general?.comissionDate,
                helperText: errors.general?.comissionDate?.message
              }
            }}
          />
        )}
      />

      <TextField
        sx={{width: 400}}
        label="Validated Ports"
        {...register('general.validatedPorts')}
        error={!!errors.general?.validatedPorts}
        helperText={errors.general?.validatedPorts?.message}
        fullWidth
      />

      <Controller
        name="general.warrantyService"
        control={control}
        render={({field}) => (
          <FormControlLabel
            sx={{
              display: 'flex', 
              alignContent: 'center', 
              justifyContent: 'flex-start', 
              width: "400px"
            }}
            control={
              <Switch
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                color="primary"
              />
            }
            label="Warranty Service"
          />
        )}
      />
      <Controller 
        name="general.scope"
        control={control}
        render={({ field }) => (
          <FormControl
            error={!!errors.general?.scope}
            style={{width: "100%"}}
          >
            <TextField
              select
              fullWidth
              {...field}
              label="scope"
              error={!!errors.general?.scope}
            >
              {scopesData.map((s: TScope, i: any) => (
                <MenuItem key={s.scopeId} value={s.scopeId}>
                  <Tooltip title={`${s.scopeName}`} >
                    <Typography variant="body2" sx={{width: "500px"}}>
                      {s.scopeName}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </TextField>
            {
              !!selectedScope ?
            <Card variant="outlined" sx={{mt:2}}>
              <CardContent>

                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                  Scope Description
                </Typography>
                <Typography >
                {scopesData?.find(
                  (sdata) => {
                    return sdata.scopeId === selectedScope
                  }
                )?.description}
                </Typography>
              </CardContent>
            </Card>
            : <></>
            }
          </FormControl>
        )}
      />
      <Box style={{width: "100%", fontSize: '20px', color: '#0F3354', marginTop: "10px"}}>
        Volume Data
      </Box>

      <Controller
        name="general.currentVolume"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <NumericFormat
            sx={{width: 400}}
            fixedDecimalScale
            label="Current Volume"
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">L</InputAdornment>,
              },
            }}
            customInput={TextField}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            getInputRef={ref}
            {...rest}
            fullWidth
          />
        )}
      />

      <Controller
        name="general.servicedVolume"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <NumericFormat
            sx={{width: 400}}
            fixedDecimalScale
            label="Serviced Volume"
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">L</InputAdornment>,
              },
            }}
            customInput={TextField}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            getInputRef={ref}
            {...rest}
            fullWidth
          />
        )}
      />

      <Controller
        name="general.totalVolume"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <NumericFormat
            sx={{width: 400}}
            fixedDecimalScale
            label="Total Volume"
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">L</InputAdornment>,
              },
            }}
            customInput={TextField}
            thousandSeparator=","
            decimalSeparator="."
            decimalScale={2}
            getInputRef={ref}
            {...rest}
            fullWidth
          />
        )}
      />

      <TextField
        sx={{width: 400}}
        label="Total Months"
        {...register('general.totalMonths')}
        error={!!errors.general?.totalMonths}
        fullWidth
      />

      <Controller
        name="general.globalReset"
        control={control}
        render={({field}) => (
          <FormControlLabel
            sx={{display: 'flex', alignContent: 'center', justifyContent: 'flex-start', width: "400px"}}
            control={
              <Switch
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                color="primary"
              />
            }
            label="Global Reset"
          />
        )}
      />
    </Box>
    ) : 
    <></>
  );
};
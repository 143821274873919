import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Card,
  CardContent,
  Tabs,
  Tab,
  Button,
} from '@mui/material';
import { FormData, ssrFormSchema } from '../../utils/schemas/ssrSchema';
import { SsrTabs } from './SsrTabs';
import { SsrGeneralForm } from '../ssr/ssrFormSubComponents/SsrGeneralForm';
import { SsrSystemInformationForm } from '../ssr/ssrFormSubComponents/SsrSystemInformationForm';
import { SsrVolumeDataForm } from '../ssr/ssrFormSubComponents/SsrVolumeDataForm';

const MultiTabForm = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(ssrFormSchema),
    defaultValues: {
      general: {
        contact: '',
        phone: '',
        comissionDate: '',
        validatedPorts: '',
        warrantyService: false,
        scope: '',
        priorDate: '',
        priorVolume: '',
        currentVolume: '',
        servicedVolume: '',
        totalVolume: '',
        totalMonths: '',
        globalReset: false,
      },
      address: {
        street: '',
        city: '',
        zipCode: '',
      },
      preferences: {
        newsletter: false,
        theme: 'light',
      },
    },
  });

  const onSubmit = (data: FormData) => {
    //Add data that should be hidden from the user, such as client or lab. 
    setEndTime(Date.now())

    console.log(
      'Form submitted:', 
      {
        ...data,
        startTime,
        endTime
        //invoiceNumber,
        //poNumber
        //client.id
        //client.lab.labId,


      });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    
    setCurrentTab(newValue);
  };

  const handleNext = () => {
      setCurrentTab(prev => {
        const value = Math.min(15, prev + 1)
        return value
      })
  };

  useEffect(() => {
    setStartTime(Date.now())
  }, [])

  useEffect(() => {
    const generalErrors = []
    for(let key in errors.general){ generalErrors.push(key)}
  }, [errors])


  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "35px" }}>
      <Box sx={{ maxWidth: 1200, mx: 'auto', p: 2 }}>
      <Box sx={{ 
        display: {
          xs: 'none', 
          sm: 'none', 
          md: 'none', 
          lg:'block'
        }, 
        fontSize:'36px', 
        backgroundColor: "white", 
        color:"#0F3354", 
        padding: "15px 10px",
        marginTop: "10px",
        marginBottom: "10px",
      }}>
        E-SSR
      </Box>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ display: 'flex', borderBottom: 1, borderColor: 'divider', height: "60vh" }}>
                <Tabs
                  indicatorColor="secondary"
                  textColor= "secondary"
                  orientation="vertical"
                  variant="scrollable"
                  value={currentTab}
                  onChange={handleTabChange}
                  aria-label="form tabs"
                  sx={{ marginTop: 0, flexGrow: 1, borderRight: 1, borderColor: 'divider', minWidth: "170px", maxWidth: "170px" }}
                >
                  <Tab 
                    sx={{alignItems: "flex-start", textAlign: "start"}}
                    icon={
                      <div style={!!errors.general ? {background: '#d32f2f', color: "white", borderRadius: "240%", padding: "8px", width: "24px", height: "24px", display: 'flex', alignItems: "center"}: {}}>
                        <span style={{marginBottom: "0"}}>{errors.general ? Object.keys(errors['general']).length : ""} </span>
                      </div>
                    } 
                    iconPosition="end" 
                    label = "General & Volume Data"
                  />
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Water Quality Readings"  />
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Sanitization" />
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Testing"/>
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Install" />
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="TOC"/>
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Analytical Samples"/>
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Settings" />
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Warning Alarm"/>
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Dispensing Methods"/>
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Volumetric Flow Dispense Verification"/>
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="VFDV"/>
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Verification"/>
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Error/Warning Codes"/>
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Notes"/>
                  <Tab sx={{alignItems: "flex-start", textAlign: "start"}} label="Service Technician" />
                </Tabs>
                <Box style={{overflow: 'auto'}}>
                  <SsrTabs value={currentTab} index={0}>
                    <SsrGeneralForm register={register} errors={errors} control={control}/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={1}>
                    <SsrSystemInformationForm register={register} errors={errors} />
                  </SsrTabs>
                  <SsrTabs value={currentTab} index={2}>
                  <SsrSystemInformationForm register={register} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={3}>
                    <SsrSystemInformationForm register={register} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={4}>
                    <SsrVolumeDataForm register={register} control={control} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={5}>
                  <SsrSystemInformationForm register={register} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={6}>
                    <SsrSystemInformationForm register={register} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={7}>
                    <SsrVolumeDataForm register={register} control={control} errors={errors} />
                  </SsrTabs>
                  <SsrTabs value={currentTab} index={8}>
                  <SsrSystemInformationForm register={register} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={9}>
                    <SsrSystemInformationForm register={register} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={10}>
                    <SsrVolumeDataForm register={register} control={control} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={11}>
                  <SsrSystemInformationForm register={register} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={12}>
                    <SsrSystemInformationForm register={register} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={13}>
                    <SsrVolumeDataForm register={register} control={control} errors={errors} />
                  </SsrTabs>
                  <SsrTabs value={currentTab} index={14}>
                  <SsrSystemInformationForm register={register} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={15}>
                    <SsrSystemInformationForm register={register} errors={errors} />
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={16}>
                    <SsrVolumeDataForm register={register} control={control} errors={errors} />
                  </SsrTabs>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Button
                  variant="outlined"
                  onClick={() => setCurrentTab(prev => Math.max(0, prev - 1))}
                  disabled={currentTab === 0}
                >
                  Previous
                </Button>
                
                {currentTab === 15 ? (
                  <Button
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default MultiTabForm;
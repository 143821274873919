import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";
import { TClientWithLabs, TClientWithLabsAndLabCount } from "../../utils/types/clientTypes";

const PAGE_SIZE = 10;

export default function useGetClientData() {
  const [isLoadingClients, setIsLoadingClients] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [cData, setClientData] = useState<TClientWithLabs[] | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const currentFetchId = useRef(0)

  const fetchData = useCallback(
    async (pageNumber: number, searchTerm: string | null, pageSize:number | null, orderBy: string|null, ascending: boolean) => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlClient?PageSize=${!!pageSize ? pageSize : 10}&PageNumber=${pageNumber}&OrderBy=${orderBy}&Ascending=${ascending}`;

      if (searchTerm != null) {
        url += "&SearchTerm=" + searchTerm;
      }

      setIsLoadingClients(true);
      setError(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        console.log(response);
        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setClientData(responsejson.clients);
          setTotalEntries(responsejson.metaData.totalEntries);
          setTotalPages(Math.ceil(totalEntries / PAGE_SIZE));
          setIsLoadingClients(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setError(error);
        setIsLoadingClients(false);
      }
    },
    [totalEntries]
  );

  const clientData = useMemo(() => {
    if (cData == null) {
      return null;
    }

  return cData.map((c): TClientWithLabsAndLabCount => {
    return {
      ...c, client: {...c.client, clientLabsCount: c.clientLabs.length}
    }
  })
}, [cData]);

  return { clientData, totalPages, totalEntries, isLoadingClients, error, fetchData };
}

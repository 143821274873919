import { Redirect, Route, Switch } from "wouter";
import { UrlPaths } from "../../utils/urlPaths";
import {Box} from '@mui/material'

import Proposals from '../../pages/proposals/Proposals';
import SalesOrders from '../../pages/salesOrders/SalesOrders';
import PurchaseOrders from '../../pages/purchaseOrders/PurchaseOrders';
import Invoices from '../../pages/invoices/Invoices';
import Clients from '../../pages/clients/Clients';
import Dashboard from '../../pages/dashboard/Dashboard';
import ClientDashboard from "../clients/ClientDashboard";
import Inventory from "../../pages/inventory/Inventory";
import SsrForm from "../ssr/SsrForm";

export default function Viewport() {
  return (
    <div className="viewport">
      <Switch>
        <Route path={UrlPaths.Dashboard}>
            <Dashboard/>
        </Route>
        <Route path={UrlPaths.Clients}>
            <Clients/>
        </Route>
        <Route path={UrlPaths.ClientDashboard}>
          <ClientDashboard/>
        </Route>
        <Route path={UrlPaths.Proposals}>
          <Box className="page-layout">
            <Proposals/>
          </Box>
        </Route>
        <Route path={UrlPaths.SalesOrders}>
          <Box className="page-layout">
            <SalesOrders/>
          </Box>
        </Route>
        <Route path={UrlPaths.PurchaseOrders}>
          <Box className="page-layout">
            <PurchaseOrders/>
          </Box>
        </Route>
        <Route path={UrlPaths.Invoices}>
          <Box className="page-layout">
            <Invoices/>
          </Box>
        </Route>
        <Route path={UrlPaths.Inventory}>
          <Box>
            <Inventory/>
          </Box>
        </Route>
        <Route path={UrlPaths.Ssr}>
          <Box>
            <SsrForm/>
          </Box>
        </Route>
        <Route>
          <Redirect to="/notfound" />
        </Route>
      </Switch>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Card,
  CardContent,
  Tabs,
  Tab,
  Button,
} from '@mui/material';

import { FormData, ssrFormSchema } from '../../utils/schemas/ssrSchema';
import { SsrTabs } from './SsrTabs';
import { SsrGeneralForm } from '../ssr/ssrFormSubComponents/SsrGeneralForm';
import Placeholder from '../placeholder/Placeholder'
import useGetScopesData from '../../hooks/scopes/useGetScopes';
import dayjs from 'dayjs';

type TabInfo = {
  tabGroup: string;
  name: string;
}

const MultiTabForm = () => {
  const {scopesData, isLoadingScopes, fetchScopesData} = useGetScopesData();
  const [currentTab, setCurrentTab] = useState(0);
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onChange",
    delayError: 500, 
    resolver: zodResolver(ssrFormSchema),
    defaultValues: {
      general: {
        contact: '',
        phone: '',
        comissionDate: dayjs(new Date()),
        validatedPorts: '',
        warrantyService: false,
        scope: '',
        priorDate: '',
        priorVolume: '',
        currentVolume: '',
        servicedVolume: '',
        totalVolume: '',
        totalMonths: '',
        globalReset: false,
      },
      waterQuality: {
        street: '',
        city: '',
        zipCode: '',
      },
      sanitization: {
        newsletter: false,
        theme: 'light',
      },
    },
  });

  const ssrTabs: TabInfo[] = [
    {tabGroup: 'general', name: "General & Volume Data"},
    {tabGroup: '', name: "Water Quality Readings"},
    {tabGroup: '', name: "Sanitization"},
    {tabGroup: '', name: "Testing"},
    {tabGroup: '', name: "Install"},
    {tabGroup: '', name: "TOC"},
    {tabGroup: '', name: "Analytical Samples"},
    {tabGroup: '', name: "Settings"},
    {tabGroup: '', name: "Warning Alarm"},
    {tabGroup: '', name: "Dispensing Methods"},
    {tabGroup: '', name: "Volumetric Flow Dispense rification"},
    {tabGroup: '', name: "VFDV"},
    {tabGroup: '', name: "Verification"},
    {tabGroup: '', name: "Error/Warning Codes"},
    {tabGroup: '', name: "Notes"},
    {tabGroup: '', name: "Service Technician"},
  ];

  const onSubmit = (data: FormData) => {
    //Add data that should be hidden from the user, such as client or lab. 
    setEndTime(Date.now())

    console.log(
      'Form submitted:', 
      {
        ...data,
        startTime,
        endTime
        //invoiceNumber,
        //poNumber
        //client.id
        //client.lab.labId,
      });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    
    setCurrentTab(newValue);
  };

  const handleNext = () => {
      setCurrentTab(prev => {
        const value = Math.min(15, prev + 1)
        return value
      })
  };

  useEffect(() => {
    setStartTime(Date.now())
  }, [])

  useEffect(() => {
    const generalErrors = []
    for(let key in errors.general){ generalErrors.push(key)}
  }, [errors])

  useEffect(() => {
    fetchScopesData()
  }, [fetchScopesData])


  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "35px" }}>
      <Box sx={{ maxWidth: 1200, mx: 'auto', p: 2 }}>
      <Box sx={{ 
        display: {
          xs: 'none', 
          sm: 'none', 
          md: 'none', 
          lg:'block'
        }, 
        fontSize:'36px', 
        backgroundColor: "white", 
        color:"#0F3354", 
        padding: "15px 10px",
        marginTop: "10px",
        marginBottom: "10px",
      }}>
        System Service Report
      </Box>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ 
                display: 'flex', 
                borderBottom: 1, 
                borderColor: 'divider', 
                height: "60vh" 
                }}
              >
                <Tabs
                  indicatorColor="secondary"
                  textColor= "secondary"
                  orientation="vertical"
                  variant="scrollable"
                  value={currentTab}
                  onChange={handleTabChange}
                  aria-label="form tabs"
                  sx={{ 
                    marginTop: 0, 
                    flexGrow: 1, 
                    borderRight: 1, 
                    borderColor: 'divider', 
                    minWidth: "170px", 
                    maxWidth: "170px" 
                  }}
                >
                  {
                    ssrTabs.map((tabInfo: TabInfo, index: number) => {
                      const getErrorCount = () => {
                        if (!tabInfo.tabGroup) return '';
                        
                        const errorsForGroup = errors[tabInfo.tabGroup as keyof FormData];
                        if (errorsForGroup && typeof errorsForGroup === 'object') {
                          return Object.keys(errorsForGroup).length;
                        }
                        return '';
                      };

                      return(
                        <Tab
                          key={window.crypto.randomUUID()}
                          sx={{ textAlign: "start", justifyContent: "flex-start"}}
                          icon={
                            <div style={
                              !!errors[tabInfo.tabGroup as keyof FormData] ? {
                                background: '#d32f2f',
                                color: "white",
                                borderRadius: "240%",
                                padding: "8px",
                                width: "24px",
                                height: "24px",
                                display: 'flex',
                                alignItems: "center"
                              }
                              : 
                              {}
                            }>
                              <span style={{marginBottom: "0"}}>{getErrorCount()}</span>
                            </div>
                          } 
                          iconPosition="end" 
                          label = {tabInfo.name}
                        />
                      )
                    })
                  }
                </Tabs>
                <Box style={{overflow: 'auto'}}>
                  <SsrTabs value={currentTab} index={0}>
                    <SsrGeneralForm control={control} errors={errors} register={register} scopesData={scopesData} watch={watch} isLoadingScopes={isLoadingScopes}  />
                  </SsrTabs>
                  <SsrTabs value={currentTab} index={1}>
                    <Placeholder/>
                  </SsrTabs>
                  <SsrTabs value={currentTab} index={2}>
                  <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={3}>
                    <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={4}>                   
                    <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={5}>
                  <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={6}>
                    <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={7}>
                    <Placeholder/>
                  </SsrTabs>
                  <SsrTabs value={currentTab} index={8}>
                  <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={9}>
                    <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={10}>                    
                    <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={11}>
                  <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={12}>
                    <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={13}>
                    <Placeholder/>
                  </SsrTabs>
                  <SsrTabs value={currentTab} index={14}>
                  <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={15}>
                    <Placeholder/>
                  </SsrTabs>

                  <SsrTabs value={currentTab} index={16}>                    
                    <Placeholder/>
                  </SsrTabs>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                <Button
                  variant="outlined"
                  onClick={() => setCurrentTab(prev => Math.max(0, prev - 1))}
                  disabled={currentTab === 0}
                >
                  Previous
                </Button>
                
                {currentTab === ssrTabs.length-1 ? (
                  <Button
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default MultiTabForm;
import React from 'react';
import { TextField, Box } from '@mui/material';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { FormData } from '../../../utils/schemas/ssrSchema';

interface AddressTabProps {
  register: UseFormRegister<FormData>;
  errors: FieldErrors<FormData>;
}

export const SsrSystemInformationForm: React.FC<AddressTabProps> = ({ register, errors }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        label="Street Address"
        {...register('address.street')}
        error={!!errors.address?.street}
        helperText={errors.address?.street?.message}
        fullWidth
      />
      <TextField
        label="City"
        {...register('address.city')}
        error={!!errors.address?.city}
        helperText={errors.address?.city?.message}
        fullWidth
      />
      <TextField
        label="ZIP Code"
        {...register('address.zipCode')}
        error={!!errors.address?.zipCode}
        helperText={errors.address?.zipCode?.message}
        fullWidth
      />
    </Box>
  );
};
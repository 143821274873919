import React from 'react';
import './App.css';
import {Box,ThemeProvider} from '@mui/material'
import GlobalStyle from "./styles/GlobalStyle"
import Login from "./pages/login/Login";
import theme from "./styles/Theme";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { UrlPaths } from "./utils/urlPaths";
import { Route, Switch } from "wouter";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import NavBar from './shared/NavBar';
import ProtectedRoute from './utils/ProtectedRoute';
import FourOFour from './pages/fourOfour';
import Viewport from './pages/page-level/ViewPort';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Switch>
        <Route path={UrlPaths.Home}>
          <Login />
        </Route>
        <Route path="/notfound">
          <FourOFour />
        </Route>
        <ProtectedRoute>
          <Box className="page-layout">
            <NavBar/>
            <Viewport />
          </Box>
        </ProtectedRoute>
      </Switch>
      </LocalizationProvider>    
    </ThemeProvider>
  );
}

export default App;

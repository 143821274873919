import React, { useCallback, useEffect, useState, useMemo } from 'react';

import { useForm, Controller} from "react-hook-form"
import {zodResolver} from '@hookform/resolvers/zod';
import { NumericFormat } from 'react-number-format'

import {
  Button, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  FormLabel, 
  MenuItem, 
  RadioGroup, 
  Switch, 
  TextField,
  FormHelperText, 
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import usePostNewClient  from '../../hooks/clients/usePostClient';
import useOneClientData from '../../hooks/clients/useOneClientData';
import { TClientData } from '../../utils/types/clientTypes';
import { HTTPMethods } from '../../utils/urlPaths';
import {
  FormInputData,
  FormOutputData, 
} from '../../utils/schemas/clientSchema';
import {states} from '../../utils/constants/states';
import PhoneNumberFields from '../../shared/PhoneNumberFields';
import { clientDataSchema } from '../../utils/schemas/clientSchema';

interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  clientData: any | null;
  isLoading: boolean;
  marketData: any;
  makeUpRateData: any;
  netTermData: any;
  taxData: any;
  shippingMethodData: any;
  onPatchSuccess: () => void;
}

interface PhoneNumber {
  type: string;
  number: string;
}

const defaultClientData = {
  marketId: "",
  taxId: "",
  termId: "",
  mkrId: "",
  accountno: "",
  company: "",
  aka: "",
  firstname: "",
  lastname: "",
  title: "",
  email: "",
  password: "",
  mainPhone: "",
  address: "",
  city: "",
  stateCode: "",
  zipcode: "",
  primaryNote: "",
  billAddress: "",
  shipAddress: "",
  shipNote: "",
  secondFname: "",
  secondLname: "",
  secondEmail: "",
  secondPhone: [{ type: '', number: '' }] as PhoneNumber[],
  secondTitle: "",
  secondNote: "",
  accFname: "",
  accLname: "",
  accEmail: "",
  accPhone: [{ type: '', number: '' }] as PhoneNumber[],
  accTitle: "",
  accNote: "",
  finW9: "",
  poFname: "",
  poLname: "",
  poEmail: "",
  poPhone: [{ type: '', number: '' }] as PhoneNumber[],
  poTitle: "",
  poNote: "",
  phoneList: [{ type: '', number: '' }] as PhoneNumber[],
  mobile: "",
  home: "",
  homePage: "",
  terms: "",
  linkedin: "",
  note: "",
  shippingMethod: "",
  balance: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(0.00),
  status: "",
  isContract: false,
  isTax: false,
  supAccountno: "",
  enable: true,
}

const AddEditClientModal: React.FC<ModalFormProps> = (
  { 
    open, 
    onClose, 
    clientData, 
    isLoading, 
    marketData, 
    makeUpRateData, 
    netTermData, 
    taxData, 
    shippingMethodData, 
    onPatchSuccess
  }) => {
    const {
      control, 
      handleSubmit, 
      reset, 
      register,
      formState: {errors}
    } = useForm<FormInputData, any, FormOutputData>({
      resolver: zodResolver(clientDataSchema),
      defaultValues: defaultClientData,
      values: useMemo(() => {
        if (!clientData) return defaultClientData;
        
        const parsePhoneData = (phoneField: string | any[] | undefined) => {
          if (!phoneField || phoneField === '') {
            return [{ type: '', number: '' }];
          }
          
          if (typeof phoneField === 'string') {
            try {
              return JSON.parse(phoneField);
            } catch (e) {
              return [{ type: '', number: '' }];
            }
          }
          
          if (Array.isArray(phoneField)) {
            return phoneField;
          }
          
          return [{ type: '', number: '' }];
        };
    
        const processedData = {
          ...clientData,
          phoneList: parsePhoneData(clientData.phoneList),
          secondPhone: parsePhoneData(clientData.secondPhone),
          accPhone: parsePhoneData(clientData.accPhone),
          poPhone: parsePhoneData(clientData.poPhone)
        };
    
        return processedData;
      }, [clientData])
    
    })

    const {createClientRequest} = usePostNewClient();
    const { oneClientData, isLoadingClientHookData } = useOneClientData();
    const [ , setFormData ] = useState<any>(defaultClientData);

    useEffect(() => {
      if (clientData === null) {
        setFormData(defaultClientData);
      }
    }, [clientData]);

    const sendEditClientData = useCallback(
      (clientData: TClientData) => {
        oneClientData(clientData.clientId, HTTPMethods.PUT, clientData);
      },
      [oneClientData]
    );

    const onFormSubmit = (formData: any) => {
      const submitData = {
        ...formData,
        phoneList: JSON.stringify(formData.phoneList),
        secondPhone: JSON.stringify(formData.secondPhone),
        accPhone: JSON.stringify(formData.accPhone),
        poPhone: JSON.stringify(formData.poPhone)
      };
  
      if (clientData) {
        sendEditClientData(submitData);
        if (!isLoadingClientHookData) {
          onPatchSuccess();
          handleClose();
        }
      } else {
        createClientRequest(submitData);
        if (!isLoadingClientHookData) {
          onPatchSuccess();
          handleClose();
        }
      }
    };

    const handleClose = () => {
      reset(defaultClientData);
      onClose();
    };

    if (!marketData || !makeUpRateData || !netTermData || !taxData || !shippingMethodData) {
      return <div></div>;
    }

    return(
        <Dialog 
          open={open} 
          maxWidth={"lg"} 
          fullWidth={true}
          sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
        >
          <DialogTitle>{clientData !== null ? "Edit Client" : "Add Client"}</DialogTitle>
            <form 
              onSubmit={
                handleSubmit(
                  (data) =>{ return onFormSubmit(data)}
                )
              }
            >
              <DialogContent>
                {
                  isLoading || isLoadingClientHookData ? (
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <div>
                      <Grid container spacing={1}>
                        <Grid size={6}>
                            <TextField
                              {...register('company')}
                              label="Company"
                              error={!!errors.company}
                              helperText={errors.company?.message}
                            />
                            <TextField 
                              {...register('aka')} 
                              label="Aka" 
                              error={!!errors.aka} 
                              helperText= {errors.aka?.message} 
                            />
                            <TextField
                              {...register('address')}
                              label="Address"
                              error={!!errors.address}
                              helperText={errors.address?.message} 
                            />
                            <TextField
                              {...register('city')}
                              label="City"
                              error={!!errors.city}
                              helperText={errors.city?.message} 
                            />

                            <Controller
                              control={control}
                              name="stateCode"
                              render={({field}) => (
                                (
                                  <FormControl 
                                    error={!!errors.stateCode}
                                    >
                                  <TextField
                                    select
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    label="State Code" 
                                    sx={{ m: 1, width: 224 }} 
                                  >
                                      {states.map((state: any, i: any) => (
                                        <MenuItem key={state} value={state}>
                                          {state}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                  <FormHelperText>{errors.marketId?.message}</FormHelperText>
                                  </FormControl>
                                )
                              )
                            }
                            />

                            <TextField
                              {...register('zipcode')}
                              label="Zip Code"
                              error={!!errors.zipcode}
                              helperText={errors.zipcode?.message} 
                            />

                            <TextField
                              {...register('billAddress')}
                              label="Billing Address"
                              error={!!errors.address} 
                              helperText={errors.address?.message}
                            />
                            <Controller
                              control={control}
                              name="marketId"
                              render={({field}) => (
                                  <FormControl 
                                    error={!!errors.marketId}
                                    >
                                  <TextField select
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    label="market" 
                                    error={!!errors.marketId} 
                                    sx={{ m: 1, width: 224 }} 
                                  >
                                      {marketData.map((market: any, i: any) => (
                                        <MenuItem key={market.marketId} value={market.marketId}>
                                          {market.marketName}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                  <FormHelperText>{errors.marketId?.message}</FormHelperText>
                                  </FormControl>
                                )
                              }
                            />
                            <Controller
                              name="isContract"
                              control={control}
                              render={({field}) => (
                                <FormControlLabel
                                  sx={{marginLeft: "5px"}}
                                  control={
                                    <Switch
                                      checked={field.value}
                                      onChange={(e) => field.onChange(e.target.checked)}
                                      color="primary"
                                    />
                                  }
                                  label={"Contract"}
                                />
                              )}
                          />
                          
                          <Controller
                            name="isTax"
                            control={control}
                              render={({field}) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={field.value}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    color="primary"
                                  />
                                }
                                label={"Tax"}
                              />
                            )}
                          />
                          <TextField
                            {...register('note')}
                            label="Facility Notes"
                            style={{width: "465px"}} 
                            error={!!errors.note}
                            helperText={errors.note?.message}
                            minRows={1}
                            maxRows={8}
                          />
                        </Grid>
                        <Grid size={6}>
                          <Controller
                            name={"balance"}
                            control={control}
                            render={({ field: { ref, ...rest } }) => (
                              <NumericFormat
                                fixedDecimalScale
                                label="Balance*"
                                helperText={errors.balance?.message}
                                error={!!errors.balance}
                                customInput={TextField}
                                thousandSeparator=","
                                decimalSeparator="."
                                decimalScale={2}
                                getInputRef={ref}
                                {...rest}
                              />
                            )}
                          />
                          <div style={{marginLeft: "10px"}}>
                            <Controller
                              name={"status"}
                              control={control}
                                render={({field}) => (
                                <FormControl {...field} error={!!errors.status}>
                                  <FormLabel>Status*</FormLabel>
                                  <RadioGroup
                                    row 
                                    name="status"
                                    value={field.value}
                                    onChange={(e) => field.onChange(Number(e.target.value))}                                    
                                  >
                                    <FormControlLabel value={2} control={<Radio /> } label="Potential" />
                                    <FormControlLabel value={1} control={<Radio /> } label="Active" />
                                    <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                  </RadioGroup>
                                  <FormHelperText>{errors.status?.message}</FormHelperText>
                                </FormControl>
                              )}
                            />
                          </div>
                          <TextField 
                            {...register('accountno')} 
                            label="Account Number"
                            error={!!errors.accountno} 
                            helperText= {errors.accountno?.message} 
                          />
                          <TextField 
                            {...register('homePage')}
                            label="Home Page" 
                            error={!!errors.homePage}
                            helperText={errors.homePage?.message} 
                          />
                          <TextField 
                            {...register('linkedin')}
                            label="Linkedin" 
                            error={!!errors.linkedin}  
                            helperText={errors.linkedin?.message}
                          />
                          <Controller
                            control={control}
                            name="mkrId"
                              render={({field}) => (
                                <FormControl 
                                  error={!!errors.mkrId}
                                  >
                                <TextField 
                                  select
                                  {...field}
                                  onChange={field.onChange}
                                  value={field.value}
                                  label="Markup Rate*" 
                                  error={!!errors.mkrId}
                                  sx={{ m: 1, width: 224 }} 
                                >
                                    {makeUpRateData.map((mkr: any, i: any) => (
                                      <MenuItem key={mkr.mkrId} value={mkr.mkrId}>
                                        {mkr.groupName}
                                      </MenuItem>
                                    ))}
                                </TextField>
                                <FormHelperText>{errors.mkrId?.message}</FormHelperText>
                                </FormControl>
                              )
                            }
                          />
                          <Controller
                            control={control}
                            name="shippingMethod"
                            render={({field}) => (
                                <FormControl 
                                  error={!!errors.shippingMethod}
                                  >
                                <TextField 
                                  select
                                  {...field}
                                  onChange={field.onChange}
                                  value={field.value}
                                  label="Shipping Method*" 
                                  sx={{ m: 1, width: 224 }} 
                                  error={!!errors.shippingMethod}
                                >
                                    {shippingMethodData.map((shipping: any, i: any) => (
                                      <MenuItem key={i} value={shipping.shipMid}>
                                        {shipping.shipMethodName}
                                      </MenuItem>
                                    ))}
                                </TextField>
                                <FormHelperText>{errors.shippingMethod?.message}</FormHelperText>
                                </FormControl>
                              )
                            }
                          />

                          <TextField 
                            {...register('shipAddress')}
                            label="Ship Address"
                            error={!!errors.shipAddress} 
                            helperText={errors.shipAddress?.message}
                          />
                          <TextField 
                            {...register('shipNote')}
                            label="Ship Note" 
                            error={!!errors.shipNote}
                            helperText={errors.shipNote?.message}
                            style={{width: "465px"}}
                          />
                        </Grid>
                        <Grid id="primaryContact" size={6}>
                          <h4>Primary Contact</h4>
                          <TextField 
                            {...register('firstname')}
                            label="Primary Contact First Name" 
                            error={!!errors.firstname}
                            helperText={errors.firstname?.message}
                          />
                        <TextField 
                          {...register('lastname')}
                          label="Primary Contact Last Name"
                          error={!!errors.lastname}
                          helperText={errors.lastname?.message}
                        />
                        <TextField 
                          {...register('title')}
                          label="Primary Contact Title" 
                          error={!!errors.title}
                          helperText={errors.title?.message}
                        />
                        <TextField
                        {...register('email')}
                          label="Email"
                          error={!!errors.email}
                          helperText={errors.email?.message} 
                        />
                        <TextField
                          {...register('primaryNote')}
                          label="Primary Contact Note" 
                          error={!!errors.primaryNote}
                          helperText={errors.primaryNote?.message}
                          style={{width: "465px"}}
                        />
                        <PhoneNumberFields phoneLabel="Main Phone" fieldArrayName='phoneList' control={control} register={register}/>
                        </Grid>
                        <Grid id="secondaryContact" size={6}>
                          <h4>Secondary Contact</h4>
                          <TextField 
                            {...register('secondFname')}
                            label="Secondary Contact First Name" 
                            error={!!errors.secondFname}
                            helperText={errors.secondFname?.message}
                          />
                          <TextField 
                            {...register('secondLname')}
                            label="Secondary Contact Last Name" 
                            error={!!errors.secondFname}
                            helperText={errors.secondFname?.message}  
                          />
                          <TextField 
                            {...register('secondEmail')}
                            label="Secondary Contact Email" 
                            error={!!errors.secondEmail}
                            helperText={errors.secondEmail?.message}
                          />
                          <TextField
                            {...register('secondTitle')} 
                            label="Secondary Contact Title" 
                            error={!!errors.secondTitle}
                            helperText={errors.secondTitle?.message}
                          />
                        <TextField 
                          {...register('secondNote')} 
                          label="Secondary Contact Notes"
                          error={!!errors.secondNote}
                          helperText={errors.secondNote?.message}
                          style={{width: "465px"}}
                        />
                        <PhoneNumberFields phoneLabel="Secondary Contact Phone" fieldArrayName='secondPhone' control={control} register={register}/>
                        </Grid>
                        <Grid id="AccountsPayable" size={6}>
                          <h4>Accounts Payable Contact</h4>
                          <TextField
                            {...register('accFname')} 
                            label="Account First Name" 
                            error={!!errors.accFname}
                            helperText={errors.accFname?.message} 
                          />
                          <TextField 
                            {...register('accLname')}
                            label="Account Last Name" 
                            error={!!errors.accLname}
                            helperText={errors.accLname?.message} 
                          />
                          <TextField 
                            {...register('accEmail')}
                            label="Account Email" 
                            error={!!errors.accEmail}
                            helperText={errors.accEmail?.message}
                          />
                          <TextField 
                            {...register('accTitle')}
                            label="Account Title" 
                            error={!!errors.accTitle}
                            helperText={errors.accTitle?.message}
                          />
                          <TextField 
                            {...register('finW9')}
                            label="w9" 
                            error={!!errors.finW9}
                            helperText={errors.finW9?.message}
                          />
                        <Controller
                          control={control}
                          name="taxId"
                          render={({field}) => (
                              <FormControl 
                                error={!!errors.taxId}
                                >
                              <TextField 
                                select
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                label="Tax*" 
                                error={!!errors.taxId} 
                                sx={{ m: 1, width: 224 }}
                              >
                                  {taxData.map((tax: any, i: any) => (
                                    <MenuItem key={i} value={tax.taxId}>
                                      {tax.taxName}
                                    </MenuItem>
                                  ))}
                              </TextField>
                              <FormHelperText>{errors.taxId?.message}</FormHelperText>
                              </FormControl>
                            )
                          }
                        />
                        <Controller
                            control={control}
                            name="termId"
                            render={({field}) => (
                                <FormControl 
                                  error={!!errors.termId}
                                  >
                                <TextField 
                                  select
                                  {...field}
                                  onChange={field.onChange}
                                  value={field.value}
                                  label="Net Terms*"
                                  error={!!errors.termId} 
                                  sx={{ m: 1, width: 224 }} 
                                >
                                    {netTermData.map((term: any, i: any) => (
                                      <MenuItem key={i} value={term.termId}>
                                        {term.termName}
                                      </MenuItem>
                                    ))}
                                </TextField>
                                <FormHelperText>{errors.termId?.message}</FormHelperText>
                                </FormControl>
                              )
                            }
                          />
                        <TextField 
                            {...register('accNote')}
                            label="Account Note" 
                            error={!!errors.accNote}
                            helperText={errors.accNote?.message}
                            style={{width: "465px"}} 
                        />
                        <PhoneNumberFields phoneLabel="Account Phone" fieldArrayName='accPhone' control={control} register={register}/>
                        </Grid>
                        <Grid size={6}>
                          <h4>Purchasing Contact</h4>
                          <TextField 
                            {...register('supAccountno')}
                            label="PLS-Sup Account #" 
                            error={!!errors.supAccountno}
                            helperText={errors.accNote?.message}
                            style={{width: "465px"}} 
                          />
                          <TextField 
                            {...register('poFname')}
                            label="poFName" 
                            error={!!errors.poFname}
                            helperText={errors.poFname?.message} 
                          />
                          <TextField 
                            {...register('poLname')}
                            label="poLName" 
                            error={!!errors.poLname}
                            helperText={errors.poLname?.message}  
                          />
                          <TextField 
                            {...register('poEmail')}
                            label="poEmail" 
                            error={!!errors.poEmail}
                            helperText={errors.poEmail?.message} 
                          />
                          <TextField 
                            {...register('poTitle')}
                            label="poTitle" 
                            error={!!errors.poTitle}
                            helperText={errors.poTitle?.message}  
                          />
                          <TextField 
                            {...register('poNote')}
                            label="poNote" 
                            error={!!errors.poNote}
                            helperText={errors.poNote?.message}
                            style={{width: "465px"}} 
                          />
                          <PhoneNumberFields phoneLabel="po Phone" fieldArrayName='poPhone' control={control} register={register}/>
                        </Grid>
                      </Grid>
                    </div>
                  )
                }
                <>
                </>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Submit</Button>
              </DialogActions>
            </form>
        </Dialog>
    )
  }

  export default AddEditClientModal;




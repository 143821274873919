import { useCallback, useMemo, useState, useRef } from "react";

import { headers } from "../authHeader";
import { TVendor } from "../../utils/types/vendorTypes";

export default function useGetMarketVendor() {
  const [isLoadingVendor, setIsLoadingVendor] = useState<boolean>(false);
  const [errorVendor, setErrorVendor] = useState<any>(null);
  const [vendorState, setVendorState] = useState<Array<TVendor> | null>(null);
  const currentFetchId = useRef(0)

  const fetchVendorData = useCallback(
    async () => {
      const fetchId = ++currentFetchId.current
      let url = `https://pls-api-appservice.azurewebsites.net/api/TlVendor`;

      setIsLoadingVendor(true);
      setErrorVendor(null);
      try {
        const response = await fetch(url, {
          headers,
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(
            "Server returned error code: " +
              response.status +
              " " +
              (await response.text())
          );
        }

        const responsejson = await response.json();
        if (fetchId === currentFetchId.current) {
          setVendorState(responsejson);
          setIsLoadingVendor(false);
        } else {
          console.log("Fetch Operation Expired")
        }
      } catch (error: any) {
        setErrorVendor(error);
        setIsLoadingVendor(false);
      }
    },
    []
  );

  const vendorData = useMemo(() => {
    if (vendorState == null) {
      return null;
    }
    return vendorState.map((v) => v);
  }, [vendorState]);

  return { vendorData, isLoadingVendor, errorVendor, fetchVendorData };
}
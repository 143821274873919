import React from 'react';
import { 
  Box, 
  Checkbox, 
  FormControlLabel, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  FormHelperText 
} from '@mui/material';
import { UseFormRegister, FieldErrors, Control, Controller } from 'react-hook-form';
import { FormData } from '../../../utils/schemas/ssrSchema';

interface PreferencesTabProps {
  register: UseFormRegister<FormData>;
  control: Control<FormData>;
  errors: FieldErrors<FormData>;
}

export const SsrVolumeDataForm: React.FC<PreferencesTabProps> = ({ register, control, errors }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Controller
        name="preferences.newsletter"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={onChange}
              />
            }
            label="Subscribe to newsletter"
          />
        )}
      />
      <Controller
        name="preferences.theme"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl 
            fullWidth
            error={!!errors.preferences?.theme}
          >
            <InputLabel id="theme-select-label">Theme Preference</InputLabel>
            <Select
              labelId="theme-select-label"
              label="Theme Preference"
              value={value}
              onChange={onChange}
            >
              <MenuItem value="light">Light</MenuItem>
              <MenuItem value="dark">Dark</MenuItem>
              <MenuItem value="system">System</MenuItem>
            </Select>
            {errors.preferences?.theme && (
              <FormHelperText>{errors.preferences.theme.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Box>
  );
};